import React, { useState } from 'react';
import '../css/Chess.css';
import { FaClipboard, FaCheckCircle, FaArrowLeft } from 'react-icons/fa';
const Fitness = ({ onBack }) => {
    const [copied, setCopied] = useState(null);
    const [modalImage, setModalImage] = useState(null);


    const handleCopy = (text, id) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(id);
            setTimeout(() => setCopied(null), 1500);
        }).catch((err) => {
            console.error('error', err);
        });
    };

    const openModal = (imageSrc) => {
        setModalImage(imageSrc);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    return (
        <div className="background">
            <div className='ContainerChess'>
                <div className="ChessContainer">
                    <div className='flex gap'>
                        <span className='Chessdisplay' onClick={onBack}>
                            <FaArrowLeft className='arrowleftChess' style={{ color: 'white', fontSize: '24px' }} />
                        </span>
                        <p>Design</p>
                    </div>
                        <div className='mt FitnessImages'>
                            <img src='Projects/FitnesStats.png' alt='FitnesStats' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnesStats.png')} />
                            <img src='Projects/FitnessRanking.png' alt='FitnessRanking' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnessRanking.png')} />
                            <img src='Projects/FitnesSettings.png' alt='FitnesSettings' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnesSettings.png')} />
                            <img src='Projects/FitnessCalendar.png' alt='FitnessCalendar' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnessCalendar.png')} />
                            <img src='Projects/FitnessHome.png' alt='FitnessHome' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnessHome.png')} />
                            <img src='Projects/FitnessProfile.png' alt='FitnessProfile' className='imgFitnessDisplay' onClick={() => openModal('Projects/FitnessProfile.png')} />
                        </div>
                    {modalImage && (
                        <div className="modal" onClick={closeModal}>
                            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                <img src={modalImage} alt="Full Screen Chess" className="modal-image" />
                            </div>
                        </div>
                    )}
                    <p className='mt'>Steps to Run the Application</p>
                    <p className='mt'>Clone the Repository:</p>
                    <div className='CopyArea mt' onClick={() => handleCopy('git clone https://github.com/Drojecki/React_Web_Fitness-App.git cd React_Web_Fitness-App', 'repo')}>
                        <div>
                            <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%',wordBreak: 'break-all' }}>git clone https://github.com/Drojecki/React_Web_Fitness-App.git</p>
                            <p>cd React_Web_Fitness-App</p>
                        </div>
                        {copied === 'repo' ? <FaCheckCircle className="copy-icon" style={{ color: 'green' }} /> : <FaClipboard className="copy-icon" />}
                    </div>
                    <p className='mt'>Run the Application:</p>

                    <div className='CopyArea mt' onClick={() => handleCopy('docker-compose up', 'docker')}>
                        <div>
                            <p>docker-compose up</p>
                        </div>
                        {copied === 'docker' ? <FaCheckCircle className="copy-icon" style={{ color: 'green' }} /> : <FaClipboard className="copy-icon" />}
                    </div>
                    <p className='mt'>Acces the application:</p>
                    <div className='RepoChess mt'>
                        <p>Frontend: Open <a target='_' href='http://localhost:3000'>http://localhost:3000</a> in your browser to view the React frontend.</p>
                        <p className='mt'>Backend API: The backend API runs on <a target='_' href=' http://localhost:5000'> http://localhost:5000</a>.</p>
                        <p className='mt'>Troubleshooting If you encounter issues on the first run, try:</p>
                    </div>
                    <div className='CopyArea mt' onClick={() => handleCopy('docker-compose down docker-compose up --build', 'dockerdown')}>
                        <div>
                            <p>docker-compose down</p>
                            <p>docker-compose up --build</p>
                        </div>
                        {copied === 'dockerdown' ? <FaCheckCircle className="copy-icon" style={{ color: 'green' }} /> : <FaClipboard className="copy-icon" />}
                    </div>
                    <p className='mt'>HOW TO USE</p>
                    <p className='RepoChess mt'>If the application starts successfully, open <a target='_' href='http://localhost:3000'>http://localhost:3000/</a> and log in using one of the available accounts:</p>
                    <div className='CopyArea mt' onClick={() => handleCopy('Login: 321 Password: 123', 'dockeradmin')}>
                        <div>
                            <p>Login: 321</p>
                            <p>Password: 123</p>
                        </div>
                        {copied === 'dockeradmin' ? <FaCheckCircle className="copy-icon" style={{ color: 'green' }} /> : <FaClipboard className="copy-icon" />}
                    </div>
                    <div className='mt imgCHessTEst'>
                        <img src='Projects/LoginFitness.png' className='imgChess' alt='imgChess' />
                    </div>
                    <p className='mt'>Alternatively, you can register your own account.</p>
                    <p className='mt'> HAVE FUN!!</p>
                </div>
            </div>
        </div>
    );
};

export default Fitness;
